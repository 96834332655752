/* .App {
  text-align: center;
} */

header {
  position: fixed;
  width: 100vw;
  left: 0;
  top: 0;
  text-align: center;
  background: white;
  box-shadow: 0 0 3px gray;
  border-bottom: 3px solid #caa819
}

main {
  height: calc(100vh - 65px);
  /* padding-top: 65px; */
  padding: 65px 0;
  overflow-x: hidden;
  background: rgb(146,146,149);
  background: linear-gradient(163deg, rgba(146,146,149,1) 35%, rgba(74,74,74,1) 81%);
}

.content {
  background: white;
  padding: .5rem;
}

/* .document-info {
  background: green;
} */

/* footer {
  position: fixed;
  bottom: 0;
} */
.disclaimer {
  text-align: center;
  font-size: .85rem;
}

.sigCanvas {
  width: 100%;
  height: 200px;
  background: white;
  border: 1px solid black;
}

footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: white;
  text-align: center;
}

footer p {
  margin: 0;
  position: absolute;
  right: 5px;
  bottom: 5px;
  font-size: .5rem;
}






hr {
  border: none;
  border-top: 1px solid #caa819;
}

p {
  margin-top: 0;
}

h1 {
  font-size: 1rem;
  text-align: center;
  text-decoration: underline
}

.btn-bar {
  display: flex;
  justify-content: space-around;
}

.sc-btn {
  background: radial-gradient(#eee243 30%, #aa8b00 100%);
  padding: 0.5em 1.5em;
  border: none;
  border-radius: 0.25em;
  color: #002357;
  box-shadow: 0px 0px 3px #002357;
  font-size: 0.65rem;
  font-weight: 600;
}

.sc-btn:active {
  transform: translateY(4px);
  box-shadow: none;
}

.sc-btn:focus {
  outline: none;
  border: 3px solid black;
/* box-shadow: 3px 3px 5px #525252; */
  box-shadow: none;
}

.sc-btn:hover {
  background-image: radial-gradient(#ecc526 15%, #bb741e 90%);
}

.alert {
  text-align: center;
  font-weight: 500;
  padding: .25rem;
}

.alert-danger {
  color: #7a0000;
  background: #f8c9c9;
}

.alert-success {
  color: #004400;
  background: #ceffce;
}
